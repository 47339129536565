import React from 'react'

export const StorreGalleryNull = () => {
  return (
    <div className="blank-container">
      <h1> Loja não encontrada. </h1>
      <div className="intruction-container">
        <h3>Verifique se a sua busca está correta</h3>
        <ul className="blank-list">
          <li>verificar a ortografia da palavra</li>
          <li>usar apenas uma palavra</li>
          <li>buscar por termos genéricos</li>
          <li>tentar o uso de sinônimos</li>
        </ul>
      </div>
    </div>
  )
}
