import React, { useState } from 'react'
import axios from 'axios'
import './styles.scss'
import ProductGrid from 'src/components/product/ProductGrid'
import type { ProductType } from 'src/components/product/ProductCard/ProductCardTypes'

import { StorreGalleryNull } from './StorreGalleryNull'
import type { FaststoreProduct, IOProduct, PlpProps } from './types'
import { parseIOProductToFaststoreProduct } from './utils'

interface Props {
  plpProps: PlpProps
}

interface SellerProps {
  slug: string
  page: number
}

interface GalleryPage {
  products: FaststoreProduct[]
  page: number
}

async function getPage(sellerProps: SellerProps) {
  return axios
    .post('/api/store/getStorePage', {
      sellerProps,
    })
    .then((response) => response.data?.products)
}

function StoreGallery({ plpProps }: Props) {
  const { products: ioProducts, pagination, slug } = plpProps

  const [galleryPages, setGalleryPages] = useState<GalleryPage[]>([
    {
      products: ioProducts.map((ioProduct: IOProduct) =>
        parseIOProductToFaststoreProduct(ioProduct)
      ),
      page: pagination.current.index,
    },
  ])

  const [currentPage, setCurrentPage] = useState<number>(
    pagination.current.index
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFetchNextProducts = async () => {
    setIsLoading(true)
    const response: IOProduct[] = await getPage({ slug, page: currentPage + 1 })

    setGalleryPages((prev) => [
      ...prev,
      {
        products: response.map((ioProduct: IOProduct) =>
          parseIOProductToFaststoreProduct(ioProduct)
        ),
        page: currentPage + 1,
      },
    ])
    setCurrentPage(currentPage + 1)
    setIsLoading(false)
  }

  return (
    <div className="plp-seller-container">
      <h1 className="plp-seller-title">{slug}</h1>
      <div className="plp-seller-product-grid">
        {ioProducts.length > 0 ? (
          galleryPages.map((galleryPage) => (
            <ProductGrid
              products={galleryPage.products as ProductType[]}
              page={galleryPage.page}
              pageSize={16}
              sort="score_desc"
              key={`seller-page-${galleryPage.page}`}
            />
          ))
        ) : (
          <StorreGalleryNull />
        )}
      </div>
      {currentPage < pagination.last.index && (
        <button
          className="plp-seller-fetchButton"
          onClick={() => !isLoading && handleFetchNextProducts()}
        >
          {isLoading ? 'Carregando...' : 'Carregar Mais'}
        </button>
      )}
    </div>
  )
}

export default StoreGallery
