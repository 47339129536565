import type {
  IOProduct,
  FaststoreProduct,
  SellersEntity,
  GetAdditionalPropertyProps,
} from './types'

const getProductOffers = (
  seller: SellersEntity | undefined,
  product: IOProduct
) => {
  return {
    lowPrice:
      (seller?.commertialOffer.AvailableQuantity ?? 0) > 0
        ? seller?.commertialOffer.Price ??
          product.priceRange.sellingPrice.lowPrice
        : 0,
    offers: [
      {
        availability:
          (seller?.commertialOffer.AvailableQuantity ?? 0) > 0
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
        price:
          seller?.commertialOffer.Price ??
          product.priceRange.sellingPrice.lowPrice,
        listPrice:
          seller?.commertialOffer.ListPrice ??
          product.priceRange.sellingPrice.lowPrice,
        quantity: seller?.commertialOffer.AvailableQuantity ?? 0,
        seller: {
          identifier: seller?.sellerId ?? '',
        },
      },
    ],
  }
}

const getAdditionalProperties = ({
  variations,
  properties,
}: GetAdditionalPropertyProps) => {
  return (
    variations
      ?.filter((variation) => variation !== null)
      .map((variation) => ({
        propertyID: variation.name,
        name: variation.name,
        value: variation.values ? variation.values[0] : '',
        valueReference: 'SPECIFICATION',
      })) ??
    properties
      ?.filter((property) => property.name !== 'sellerId')
      .map((property) => ({
        propertyID: property.originalName,
        name: property.name,
        value: property.values ? property.values[0] : '',
        valueReference: 'SPECIFICATION',
      })) ??
    []
  )
}

export const parseIOProductToFaststoreProduct = (
  product: IOProduct
): FaststoreProduct => {
  const mainSKU =
    product.items?.find((item) =>
      item.sellers?.some(
        (seller) => seller.commertialOffer.AvailableQuantity > 0
      )
    ) ?? product.items?.[0]

  const mainSeller =
    mainSKU?.sellers?.find(
      (seller) => seller.commertialOffer.AvailableQuantity > 0
    ) ?? mainSKU?.sellers?.[0]

  return {
    id: mainSKU?.itemId ?? product.productId,
    sku: mainSKU?.itemId ?? '',
    releaseDate: `${product.releaseDate}`,
    name: product.productName,
    gtin: mainSKU?.referenceId?.[0]?.Value ?? product.productReference,
    description: product.description,
    slug: `${product.linkText}-${mainSKU?.itemId ?? product.productId}`,
    clusterHighlights: product.clusterHighlights,
    isVariantOf: {
      name: product.productName,
      productGroupID: product.productId,
      additionalProperty: getAdditionalProperties({
        properties: product?.properties,
      }),
      hasVariant: product.items?.map((item) => {
        const itemSeller =
          item.sellers?.find(
            (seller) => seller.commertialOffer.AvailableQuantity > 0
          ) ?? item.sellers?.[0]

        return {
          sku: item.itemId,
          name: item.name,
          description: item.complementName || '',
          offers: getProductOffers(itemSeller, product),
          additionalProperty: getAdditionalProperties({
            variations: item.variations,
          }),
        }
      }),
    },
    additionalProperty: getAdditionalProperties({
      variations: mainSKU?.variations,
    }),
    image: mainSKU?.images?.map((image) => ({
      url: image.imageUrl,
      alternateName: image.imageLabel,
    })),
    brand: { name: product.brand, brandName: product.brand },
    offers: getProductOffers(mainSeller, product),
    breadcrumbList: {
      itemListElement: [
        ...(product?.categories?.reverse()?.map((category, index) => ({
          item: category
            .toLowerCase()
            .replaceAll(' ', '-')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
          name: category.split('/').filter(Boolean).pop() ?? '',
          position: +index + 1,
        })) ?? []),
        {
          item: product.link,
          name: formatUrlPath(product.link) ?? '',
          position: (product.categories?.length ?? 0) + 1,
        },
      ],
    },
    specificationGroups: product.specificationGroups,
    brandId: product.brandId,
    categoriesIds: product.categoriesIds ?? null,
    productId: product.productId,
    productClusters: product.productClusters,
  }
}

function formatUrlPath(urlPath: string) {
  const words = urlPath.slice(1).split('-')

  const filteredWords =
    words.slice(-2, -1)[0].toLowerCase() === 'el'
      ? words.slice(0, -2)
      : words.slice(0, -1)

  return filteredWords
    .map((word: string) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ')
}
